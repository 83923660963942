/* ..............v............. */
.servicemod button {
        display: block;
        margin: 13px auto;
        min-width: 153px;
      }
      .servicemod button {
        display: block;
        margin: 20px auto;
        min-width: 153px;
        background-color: #9c27b0;
        color: white;
        font-size: 14px;
        border: 1px solid #9c27b0;
        border-radius: 3px;
        padding: 8px;
        box-shadow: -7px -3px 7px 3px #c4c2c2;
        font-weight: 700;
    }
    .servicemod p{
        font-size: 29px;
        font-weight: bold;
        margin-bottom: 37px;
        margin-top: 18px;
    }
    .sertext {
      font-size: 16px;
      margin-bottom: 37px;
      margin-top: 18px;
    }
    .inpts input {padding: 6px 0 3px 0 !important;}
    .signn_input  input {padding: 16px 0   !important;}
    .upload p{font-weight:bold;color:#000}
    .primary_label{font-weight:bold;color:#000;font-size:16px}
    .item_inner_img img{
      max-height: 300px;
    min-height: 300px;
    object-fit: cover;
    border-radius: 20px;
    }
    .item_inner_img video{
      max-height: 300px;
    min-height: 300px;
    object-fit: cover;
    border-radius: 20px;
    }
    .file_btn{padding-left:0px;}
    .item_inner_img{
      display: block;
    max-width: 400px;
    min-width: 400px;
    }
    .item_inner_img audio{
      width:100%
    }
    .validation_txt{
      color: red !important;
      font-weight: 500;
    }
.signn_input .grid_seven
    {
      background-color:#3f51b5;
    }

    .signn_input .grid_five
    {
      background-color:#fff;
    }

    .signn_input .grid_five>div
    {
      justify-content: center;
      margin-top: 0px;
      height: 100%;
      margin-bottom: 0px;
    }